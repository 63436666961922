import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Icon } from 'semantic-ui-react';
import { isEmail } from '../../util';
import styles from './EmailForm.module.css';

interface State {
  firstName: string;
  lastName: string;
  email: string;
  botField?: string;
}

const initialFormFields = {
  firstName: '',
  lastName: '',
  email: '',
};

// https://www.netlify.com/blog/2017/07/20/how-to-integrate-netlifys-form-handling-in-a-react-app/
const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

const recaptchaRef = React.createRef<ReCAPTCHA>();

class EmailForm extends React.Component<{}, State> {
  constructor(props) {
    super(props);
    this.state = { ...initialFormFields };
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>, inputName: string) => {
    if (inputName === 'firstName') {
      return this.setState({
        firstName: e.target.value,
      });
    }
    if (inputName === 'lastName') {
      return this.setState({
        lastName: e.target.value,
      });
    }
    if (inputName === 'email') {
      return this.setState({
        email: e.target.value,
      });
    }
    if (inputName === 'botField') {
      return this.setState({
        botField: e.target.value,
      });
    }
  };

  handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const recaptchaToken = await recaptchaRef.current.executeAsync();

      const { firstName, lastName, email, botField } = this.state;
      const trimmedFirstName = firstName.trim();
      const trimmedLastName = lastName.trim();
      const trimmedEmail = email.trim();

      if (!trimmedFirstName || !trimmedLastName || !trimmedEmail) {
        alert('Please fill out all fields.');
        return;
      }

      if (isEmail(trimmedFirstName) || isEmail(trimmedLastName) || !isEmail(trimmedEmail)) {
        alert('Please fill fields appropriately.');
        return;
      }

      const body = {
        firstName: trimmedFirstName,
        lastName: trimmedLastName,
        email: trimmedEmail,
        botField,
        'g-recaptcha-response': recaptchaToken,
      };

      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': 'Email subscription', ...body }),
      }).then((res) => {
        if (res.status === 200) {
          alert('You are successfully subscribed for Forefront emails');
          this.setState({ ...initialFormFields });
        }
      });
    } catch (error) {
      alert('Unable to subscribe. Please try again later');
    }
  };

  render() {
    const { firstName, lastName, email, botField } = this.state;

    return (
      <form
        name='Email subscription'
        method='POST'
        data-netlify-recaptcha='true'
        data-netlify='true'
        data-netlify-honeypot='botField'
        onSubmit={this.handleSubmit}
      >
        <ReCAPTCHA
          className={styles.root}
          ref={recaptchaRef}
          size='invisible'
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
        >
          <label className={styles.hidden}>
            Are you human
            <input name='botField' value={botField} onChange={(e) => this.handleChange(e, 'botField')} />
          </label>

          <div className={styles.inputContainer}>
            <input
              required
              className={styles.input}
              type='text'
              name='firstName'
              aria-label='First Name'
              placeholder='First Name'
              value={firstName}
              onChange={(e) => this.handleChange(e, 'firstName')}
              autoComplete='new-password'
            />
          </div>
          <div className={styles.inputContainer}>
            <input
              required
              className={styles.input}
              type='text'
              name='lastName'
              aria-label='Last Name'
              placeholder='Last Name'
              value={lastName}
              onChange={(e) => this.handleChange(e, 'lastName')}
              autoComplete='new-password'
            />
          </div>
          <div className={styles.inputContainer}>
            <input
              required
              className={styles.input}
              type='email'
              name='email'
              aria-label='Email'
              placeholder='Email Address'
              value={email}
              onChange={(e) => this.handleChange(e, 'email')}
              autoComplete='new-password'
            />
          </div>

          <div className={styles.buttonContainer}>
            <button type='submit' className={styles.notButton}>
              <Icon name='arrow right' size='large' />
            </button>
          </div>
        </ReCAPTCHA>
      </form>
    );
  }
}

export default EmailForm;
