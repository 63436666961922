const DonationWidget = () => {
  return (
    <div>
      {/* @ts-ignore */}
      <givebutter-widget id='gJ4rdj' align='center'></givebutter-widget>
    </div>
  );
};

export default DonationWidget;
